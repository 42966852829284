import React, {Component} from 'react'
import {BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import BoardPage from './BoardPage'
import Home from './Home'
import { client } from './client'
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "https://boardhunter.us17.list-manage.com/subscribe/post?u=d62e034d80f221c70d301681b&amp;id=0bb79e8fe7"

class App extends Component {
  constructor(props){
      super(props)

      this.state = {
          boards: []
      }
  }

  componentDidMount() {
    client.getEntries()
    .then((response) => {
      this.setState({boards: response.items})
    })
    .catch(console.error)
  }


  render() {
    return (
      <div>
        <Router>

        <header>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav>
                  <div className="logo"><Link to="/">Board Hunter</Link></div>
                  <span>Best price on any board</span>
                </nav>
              </div>
            </div>

          </div>
          {/* <nav>
            {
              this.state.boards.map((e,i) => {
                return <Link key={i} to={"/" + e.fields.boardUrl}>{e.fields.boardUrl}</Link>
              })
            }
          </nav> */}
        </header>

          <Switch>
            <Route exact path="/" render={() => <Home data={this.state.boards} /> } />
            {
              this.state.boards.map((e,i) => {
                return <Route key={i} path={"/" + e.fields.boardUrl.toLowerCase()} render={()=><BoardPage data={e} all_data={this.state.boards} />} />
              })
            }
          </Switch>
        </Router>
        <footer>
          <div className="row">
            <div className="col-12">
              <div className="footer-copy"> 
              <h2>Sign Up for Early Access</h2>
              <p>Receive offers and early pricing only available for subscribers.</p>
              
              <div className="mailForm" name="signup">
                <a className="pageAnchor" href="/" name="signup" visibility="hidden">price alerts signup</a>
                <MailchimpSubscribe url={url}/>
              </div>

              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default App;
