import React, { Component } from 'react'
import BoardTile from '../BoardTile'
import LevelBox from '../Utils/level'
import Star from '../../Images/rating.svg'
import { Helmet } from 'react-helmet'
import {Mail} from 'react-feather'
import ReactMarkdown from 'react-markdown'
// import person from '../../Images/person.png'
// import star from '../../Images/rating.png'

const CopyText = (props) => {
    return props.data.content.map((e,i) => {
        // <ReactMarkdown source={boardBrandInfo.content[0].content[0].value} /> 
        // return <p key={i}>{e.value}</p>
        return <ReactMarkdown key={i} source={e.value} /> 
    })
}

const CopyList = (props) => {
    return props.data.content.map((e,i) => {
       
        return <li key={i}>{e.content[0].content[0].value}</li>
    })
}

class BoardPage extends Component {
    constructor(props){
        super(props)

        this.state = {
            item: this.props.data
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0) 
    }

    render() {

        const {
            boardBrand, 
            boardName,
            boardTagline,
            boardShortDescription,
            boardLongDescription,
            boardSpecs,
            priceList,
            rating,
            boardSize,
            boardBrandInfo,
            boardImages,
            idealHeight,
            idealWeight,
            boardUrl
        } = this.state.item.fields

        const stars = [1,2,3,4,5]

        return (
            <div className="boardpage">
                <div className="container">
                    <div className="row">
                        <div className="col-12-sm col-8">
                            <div className="product-images">
                                {
                                    boardImages.map((e,i) => {
                                        return <img key={i} src={e.fields.file.url} alt="board" width="100%" />
                                    })
                                }
                                
                            </div>
                        </div>
                        <div className="col-12-sm col-4 product-details">
                            <div className="rating"> 
                                {
                                    stars.map((e,i) => {
                                        return i <= rating ? (
                                             <img className="red" key={i} src={Star} alt="rating" />
                                        ) : (
                                             <img key={i} src={Star} alt="rating" />
                                        )
                                        
                                    })
                                }
                            </div>

                            <h1>{boardBrand + " " + boardName}</h1>

                            <div className="attrib">
                                <p>Available prices</p>
                                <div className="board-prices">
                                    {
                                        priceList.map((e,i) => {
                                            return (
                                                <div key={i} className="price">
                                                    <span>{i === 0 ? "" : "$"}{e.price}
                                                        <var>{e.brand}</var>
                                                    </span>
                                                    <a href={e.url} target="_blank" rel="noopener noreferrer" id={e.brand + ' - ' + boardName}>Buy from site</a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="attrib">
                                <p>This board is suited for</p>
                                <LevelBox height={idealHeight} weight={idealWeight} />
                            </div>

                            <div className="attrib">
                                <p>Available sizes</p>
                                <div className="board-sizes">
                                    <div className="size selected">
                                        {boardSize}
                                        <span>Feet</span>
                                    </div>
                                </div>
                            </div>

                            <div className="attrib">
                                <a className="price-alerts" href="#signup"><Mail />Sign Up for Price Alerts</a>
                            </div>

                        </div>
                    </div>

                </div>


                <div className="tab-bg">
                    <div className="container">
                    <div className="tabbed">
                        <div className="row">
                            <div className="col-4">
                                <div className="tab">
                                    <h3>Board Description</h3>        
                                    {
                                        boardLongDescription.content.map((e,i) => {
                                           return e.nodeType === 'paragraph' ? (<CopyText key={i} data={e}/>) : (<ul><CopyList key={i} data={e}/></ul>)
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="tab">
                                    <h3>Board specifications</h3>
                                    {
                                        boardSpecs.content.map((e,i) => {
                                           return e.nodeType === 'paragraph' ? (<CopyText key={i} data={e}/>) : (<ul><CopyList key={i} data={e}/></ul>)
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="tab">
                                    <h3>About {boardBrand}</h3>
                                    {
                                        
                                    }
                                    {
                                        !boardBrandInfo ? ('') : ( 
                                            boardBrandInfo.content.map((e,i) => {
                                                return e.nodeType === 'paragraph' ? (<CopyText key={i} data={e}/>) : (<ul><CopyList key={i} data={e}/></ul>)
                                             })
                                        )
                                    }                          
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="related-bg">
                    <div className="container">
                            <h3>Our selection of beginner boards for July</h3>
                            <div className="row">
                            {
                                    this.props.all_data.map((e,i) => {
                                        return (
                                            <div className="col-4" key={i}>
                                                <BoardTile data={e} />
                                            </div>
                                        )
                                    })
                                }
.
                            </div>
                        </div>
                    </div>

                    <Helmet>
                        <title>{boardUrl.replace(/-/g, ' ').toLowerCase()} - Board Hunter</title>
                        <link rel="canonical" href={"https://www.boardhunter.com.au/" + boardUrl.toLowerCase()} />
                        <meta property="og:title" content={boardTagline} />
                        <meta property="og:image" content={"https:" + boardImages[0].fields.file.url} />
                        <meta property="og:description" content={boardShortDescription} />
                    </Helmet>
            </div>
        )
    }
}

export default BoardPage;
