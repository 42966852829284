import React from 'react'

const LevelBox = (props) => {

    return (
        <div className="board-for">
            <div className="level">
                Beginner
                <span>Level</span>
            </div>
            <div className="height">
                {props.height}
                <span>Height</span>
            </div>
            <div className="weight">
                {props.weight}kg
                <span>Weight</span>
            </div>
        </div>
    )
}

export default LevelBox