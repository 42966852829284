import React, {Component} from 'react'
import BoardTile from '../BoardTile'
import {Search, Sliders, Users, Heart } from 'react-feather'
import {Mail} from 'react-feather'

class Home extends Component {

    render() {
        return (
            <div className="home-oage">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="masthead">
                                <h1>BEGINNER SURFBOARDS</h1>
                                <p>We surf the web daily to help you find the latest info on every surfboard from around the world.</p>
                                <a className="price-alerts" href="#signup"><Mail />Sign Up for Early Access</a><br/>
                                <a className="scolldown" href="#boards">Our selection of beginner boards</a>
                            </div>
                            <div className="benefits">
                                <div className="benefit">
                                    <Sliders />
                                    <h3>Find your perfect board</h3>
                                    <p>Our data led recommendation engine matches the best surfboard for you regardless of skill and size</p>
                                </div>
                                <div className="benefit">
                                    <Search />
                                    <h3>Search for any board</h3>
                                    <p>We aggregate every surfboard from the web to help you explore, compare and get the best price</p>
                                </div>
                                <div className="benefit">
                                    <Users />
                                    <h3>What makes a surfboard awesome?</h3>
                                    <p>Learn from surfboard makers, enthusiasts and professionals</p>
                                </div>
                                <div className="benefit">
                                    <Heart />
                                    <h3>Track boards for your quiver</h3>
                                    <p>Sign up to receive price and availability alerts for your next surfboard, plus updates on new board releases</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home-boards">
                    <a className="home-anchor" name="boards" href="/">Beginer boards page anchor</a>
                    <div className="container">
                        <h3>Our selection of beginner boards for July</h3>
                        <div className="row">
                                {
                                    this.props.data.map((e,i) => {
                                        return (
                                            <div className="col-4" key={i}>
                                                <BoardTile data={e} />
                                            </div>
                                        )
                                    })
                                }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home