import React, {Component} from 'react'
import LevelBox from '../Utils/level'
import {Link} from 'react-router-dom'

class BoardTile extends Component {
  constructor(props){
      super(props)

      this.state = {
          boards: []
      }
  }

  render() { 
    const {
            boardBrand, 
            boardName,
            boardImages,
            boardUrl,
            idealHeight,
            idealWeight,
            priceList
        } = this.props.data.fields

      return (
          <Link className="boardLink" to={"/" + boardUrl.toLowerCase() }>
            <div className="board-tile">
                <div className="board-brand">{boardBrand}</div>
                <div className="board-name">{boardName}</div>
                <div className="best-price">Best Price ${priceList[0].price}</div>
                <div className="board-lvl-box">
                    <LevelBox height={idealHeight} weight={idealWeight} />
                </div>
                <div className="board-img">
                    <img src={boardImages[0].fields.file.url} alt="board" />
                </div>
            </div>
          </Link>
      )
  }
}

export default BoardTile